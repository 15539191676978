import React from "react";
import "./Test.css";
import $ from "jquery";
import { useEffect } from "react";

export default function Test(props) {
  const { progress } = props;

  useEffect(() => {
    var elem = document.querySelector(".circlePercent");
    var percent = 0;
    var stopped = false;
    var stopPercent = 100; //Enter variable name to make it dynamic

    function setProgress(elem, percent) {
      var degrees = percent * 3.6,
        transform = /MSIE 9/.test(navigator.userAgent)
          ? "msTransform"
          : "transform";
      elem
        .querySelector(".counter")
        .setAttribute("data-percent", Math.round(percent));
      elem.querySelector(".progressEnd").style[transform] =
        "rotate(" + degrees + "deg)";
      elem.querySelector(".progress").style[transform] =
        "rotate(" + degrees + "deg)";
      if (percent >= 50 && !/(^|\s)fiftyPlus(\s|$)/.test(elem.className))
        elem.className += " fiftyPlus";
    }
    function animate() {
      // TODO 0.25, 1 based on pending
      setProgress(elem, (percent += 0.25));
      if (percent < 100 && !stopped) setTimeout(animate, 1);
      if (percent == stopPercent) {
        stopped = true;
      }
    }

    animate();
  }, []);

  return (
    <div className="circle-progrees-loading">
      <div className="container">
        <div className="vertical-centered-box">
          <div className="circlePercent" id="circlePercent">
            <div className="counter" data-percent="100"></div>

            <div className="progress"></div>
            <div className="progressEnd"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
