import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import formatThousands from "format-thousands";
import UserHeader from "./includes/UserHeader";
import UserService from "../../../services/user.service";
import moment from "moment";
import Footer from "./includes/Footer";
import thumbUp from "../Dashboard/includes/images/thumb-up-icon.png";
import pendingIcon from "../Dashboard/includes/images/clock-icon.png";

export default function ViewSpiffs() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [sortCol, setSortCol] = useState("true");
  const [sortDir, setSortDir] = useState("true");
  const [sortColErr, setSortColErr] = useState("");
  const [sortDirErr, setSortDirErr] = useState("");
  const [dirDisabled, setDirDisabled] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { accessToken, id } = state;
  const navigate = useNavigate();

  const spiffsList = async (page, limit, values, sortCol, sortDir) => {


    if (sortCol && sortCol != "true") {
      setDirDisabled(false);
      setSortCol(sortCol);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }

   
    if (sortCol != "true" && sortDir == "true") {
      setSortDirErr("Please select this");
      return;
    } else if (sortCol == "true" && sortDir != "true") {
      setSortColErr("Please select this");
      return;
    } else if (sortCol != "true" && sortDir != "true") {
      setSortColErr("");
      setSortDirErr("");
    }

    settableLoader(true);
    try {
      const response = await UserService.listAllOrder(
        id,
        page,
        limit,
        values,
        sortCol,
        sortDir
      );

      const res = response?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          sale_id: value.sale_id,
          sale_name: value.sale_name,
          points: value.points,
          cron: value.cron,
          date: value.created_at,
          reportDate: value.cron_date,
          id: value.id,
        });
      });

      settableData([...results]);
      setTotalPages(response?.data?.total_pages);
      setTotalResults(response?.data?.records);
      setCurrentPage(response?.data?.current_page);
      setLimit(response?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (accessToken) {
      spiffsList("1", limit, []);
    } else {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delGift = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleGifts(delId, id);

          spiffsList(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const deletAllGifts = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete all record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletAllGifts(id);

          spiffsList(currentPage, limit, values, sortCol, sortDir);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        spiffsList("1", limit, values, sortCol, sortDir);
      },
    });

  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design">
            <div className="user-heading-sectiom">
              VIEW <span className="p-color">SPIFFS</span>
              <div className="clearfix"></div>
            </div>

            <div className="mt-4 row">
              <div className="col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white py-3 card-header">
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="cmp-name mb-0">
                          Total Spiffs:{" "}
                          <span className="text-primary-color fw-bold">
                            {formatThousands(totalResults, {
                              separator: ",",
                            })}
                          </span>
                        </h4>
                      </div>
                      {/* <div className="col-md-4 text-end">
                        <button
                          onClick={deletAllGifts}
                          type="button"
                          className="btn btn-danger btn-sm btn-icon-text"
                        >
                          Delete All Spiffs <i className="bi bi-x-circle"></i>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="row" onSubmit={handleSubmit} noValidate>
                      <div className="col-md-2">
                        <div className="input-field-box mb-lg-0">
                          <label>Show Column</label>
                          <select
                            className={`form-select ${
                              errors.fieldtype && touched.fieldtype
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="fieldtype"
                            value={values.fieldtype || ""}
                            required
                          >
                            <option value="">Select Column</option>
                            {/* <option value="sale_id">Sales Id</option> */}
                            {/* <option value="sale_name">Sales Person Name</option> */}
                            <option value="points">Reward Amount</option>
                            <option value="created_at">Date</option>
                            <option value="cron_date">Report Date</option>
                            <option value="status">Status</option>
                          </select>
                        </div>
                        {errors.fieldtype && touched.fieldtype ? (
                          <span className="text-danger">
                            {errors.fieldtype}
                          </span>
                        ) : null}
                      </div>

                      <div className="col-md-4">
                        <div className="input-field-box mb-lg-0">
                          <label className="spiff-search">Search</label>
                          <div className="input-group">
                            {values.fieldtype !== "status" ? (
                              <input
                                type={
                                  values.fieldtype === "created_at" ||
                                  values.fieldtype === "cron_date"
                                    ? "date"
                                    : "text"
                                }
                                className={`form-control ${
                                  errors.searchval && touched.searchval
                                    ? "is-danger"
                                    : ""
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="searchval"
                                value={values.searchval || ""}
                                required
                              />
                            ) : (
                              <>
                                <label>Status</label>
                                <select
                                  className={`form-select ${
                                    errors.searchval && touched.searchval
                                      ? "is-danger"
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="searchval"
                                  value={values.searchval || ""}
                                  required
                                >
                                  <option value="">Select Status</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Pending">Pending</option>
                                </select>
                              </>
                            )}

                            <button className="btn btn-primary " type="submit">
                              Search
                            </button>
                            <button
                              className="btn btn-primary"
                              type="reset"
                              onClick={() => window.location.reload(false)}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <span className="text-danger">
                            {errors.searchval}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-md-2">
                        <div className="input-field-box mb-0">
                          <label>Show Entries</label>
                          <select
                            className="form-select"
                            value={limit}
                            onChange={(e) =>
                              spiffsList("1", e.target.value, values)
                            }
                          >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="input-field-box mb-0">
                          <label>Sort By Column</label>
                          <select
                            className="form-select"
                            value={sortCol}
                            name="sort_column"
                            onChange={(e) =>
                              spiffsList(
                                "1",
                                limit,
                                values,
                                e.target.value,
                                sortDir
                              )
                            }
                          >
                            <option value>Select Column</option>
                            <option value="points">Reward Amount</option>
                            <option value="created_at">Date</option>
                            <option value="report_date">Report Date</option>
                          </select>
                          {sortColErr && (
                            <span className="text-danger">{sortColErr}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="input-field-box mb-0">
                          <label>Sort By Direction</label>
                          <select
                            disabled={dirDisabled}
                            className="form-select"
                            value={sortDir}
                            onChange={(e) =>
                              spiffsList(
                                "1",
                                limit,
                                values,
                                sortCol,
                                e.target.value
                              )
                            }
                          >
                            <option value>Select Direction</option>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                          </select>
                          {sortDirErr && (
                            <span className="text-danger">{sortDirErr}</span>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  {tableLoader ? (
                    <div className="card-body pt-0">
                      <Skeleton count={11} height="50px" />
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="text-nowrap  table">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">RSA ID</th>
                            <th scope="col">Sales Person Name</th>
                            <th scope="col">Reward Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">Report Date</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.length ? (
                            tableData.map((el, index) => (
                              <tr key={el.id}>
                                <td>
                                  {currentPage === 1
                                    ? index + 1
                                    : (currentPage - 1) * limit + index + 1}
                                </td>
                                <td className="alig n-middle">{el.sale_id} </td>
                                <td className="align-middle">
                                  {el.sale_name}{" "}
                                </td>
                                <td className="align-middle">$ {el.points}</td>
                                <td className="align-middle">
                                  {moment(el.date).format("MM-DD-YYYY")}
                                </td>
                                <td className="align-middle">
                                  {el.reportDate !== null
                                    ? moment(el.reportDate).format("MM-DD-YYYY")
                                    : "N/A"}
                                </td>

                                {el.cron === "yes" && (
                                  <td
                                    className="yes"
                                    style={{ color: "#00b775" }}
                                  >
                                    {/* <i
                                      className="fa fa-thumbs-up"
                                      style={{ color: "#28a745" }}
                                    >
                                      {" "}
                                      Approved
                                    </i> */}
                                    <img
                                      className="thumb-up-icon"
                                      src={thumbUp}
                                      alt="icon"
                                    />
                                    {" Approved"}
                                    <br />
                                  </td>
                                )}
                                {el.cron === "no" && (
                                  <td
                                    className="no"
                                    style={{ color: "#fd6c00" }}
                                  >
                                    {/* <i
                                      className="fa fa-clock-o"
                                      style={{ color: "#e0876c" }}
                                    >
                                      {" "}
                                      Pending
                                    </i> */}
                                    <img
                                      className="thumb-up-icon"
                                      src={pendingIcon}
                                      alt="icon"
                                    />
                                    {" Pending"}
                                    <br />
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                className="text-center text-capitalize"
                              >
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <br />
                      {totalResults > limit && totalPages > 1 ? (
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={parseInt(limit)}
                          totalItemsCount={totalResults}
                          onChange={(e) => {
                            spiffsList(e, limit, values, sortCol, sortDir);
                          }}
                          pageRangeDisplayed={8}
                          itemClass="page-item"
                          linkClass="page-link"
                          firstPageText="First Page"
                          lastPageText="Last Page"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <br/>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}
