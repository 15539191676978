import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { newGiftAdd } from "../../../schema";
import Test from "../ManageDocument/Test";
import successIcon from "../login/upload-success.png";
import uploadIcon from "../login/upload_5181140.png";

export default function AddRewards() {
  const [loading, setLoading] = useState(false);
  const [dupError, setDupError] = useState("");
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();
  const [file, setFile] = useState("");

  useEffect(() => {
    if (adminAccessToken) {
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
    setFieldValue("file", e.target.value);
  };

  const AddProfileFun = async (values) => {
    const formData = new FormData();
    if (file !== "") {
      formData.append("file", file);
    }

    formData.append("adminId", parseInt(adminId));

    // formData.append("name", values.color);
    // formData.append("description", values.description);
    setLoading(true);
    try {
      const response = await AdminListService.addReward(formData);
      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/manage-reward", { replace: true });
      }, 1000);
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 422) {
        // setDupError(err.response.data.name);
      } else {
        if (
          err?.response?.data?.response &&
          typeof err?.response?.data?.response === "string"
        ) {
          toast.error(err?.response?.data?.response, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong, try again", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      // color: "",
      // description: "",
      file: "",
    },
    validationSchema: newGiftAdd,
    onSubmit: (values) => {
      AddProfileFun(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">Upload Rewards</div>

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">Upload Rewards</h4>
                    </div>
                    <div className="col-md-4 text-end">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text"
                        href="/assets/csv/Purple Spiffs Sheet.xlsx"
                      >
                        DOWNLOAD SAMPLE SHEET <i className="bi bi-download"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-md-12">
                      <div className=" file-area">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          required="required"
                          accept=".xls, .xlsx"
                          onChange={handleFileChange}
                        />
                        <div className="file-dummy">
                          <div className="success">
                            <img
                              src={successIcon}
                              alt="success-icon"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                          <br />
                          <div className="success">
                            Great, your file is selected. Keep on.
                          </div>
                          <div className="default">
                            <img
                              src={uploadIcon}
                              alt="upload-icon"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                          <div className="default">Import XLSX File</div>
                        </div>
                      </div>
                      {errors.file && touched.file ? (
                        <span className="text-danger">{errors.file}</span>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <button className="btn btn-primary mt-3" type="submit">
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )} */}
      {loading && <Test progress="100" />}
    </div>
  );
}
