import React, { useEffect, useRef } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import { toast } from "react-toastify";
import formatThousands from "format-thousands";
import thumbUp from "../../user/Dashboard/includes/images/thumb-up-icon.png";
import pendingIcon from "../../user/Dashboard/includes/images/clock-icon.png";
import $ from "jquery";

export default function ViewReports() {
  const [tableLoader, settableLoader] = useState(false);
  const [limit, setLimit] = useState("10");
  const [tableData, settableData] = useState([]);
  const [sortCol, setSortCol] = useState("true");
  const [sortDir, setSortDir] = useState("true");
  const [sortColErr, setSortColErr] = useState("");
  const [sortDirErr, setSortDirErr] = useState("");
  const [dirDisabled, setDirDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const [dateValue, setDateValue] = useState("");
  // const [dateError, setDateError] = useState("");
  const [dateName, setDateName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRecords, setIsRecords] = useState(true);
  const [isExport, setIsExport] = useState(true);
  const [isEndDate, setIsEndDate] = useState(false);
  const [isStartDate, setIsStartDate] = useState(false);

  const [selectGradient, setSelectGradient] = useState("gradient-border");
  const [stGradient, setStGradient] = useState("");
  const [endGradient, setEndGradient] = useState("");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();
  const dateRef = useRef(null);
  const endDateRef = useRef(null);
  const typeRef = useRef(null);
  const API_URL = process.env.REACT_APP_API_Link;
  const date = new Date();
  var time = date.getTime();

  const styles = {
    range: {
      pointerEvents: isExport ? "none" : "",
      opacity: isExport ? "0.6" : "1",
    },
  };

  const changeDateColumn = (e) => {
    let title = e.target.value;
    if (title) {
      setDateValue(title);
      setStGradient("");
      setStGradient("gradient-border");
      setIsStartDate(true);
      let newTitle = "Register";
      if (title == "rewards") {
        newTitle = "Rewards";
      }
      setDateName(newTitle);
    } else {
      setDateValue("");
      setDateName("");
    }
    setSelectGradient("");
  };

  // ~Start Date...............
  const changeStartDate = (e) => {
    setStGradient("");
    setEndGradient("gradient-border");
    let date = e;
    setStartDate(date);
    setIsEndDate(true);
    setIsRecords(true);
    setIsExport(true);
    endDateRef.current.value = "";

    setIsStartDate(true);
  };
  //~.....................

  // ~End Date...............
  const changeEndDate = (e) => {
    setEndGradient("");
    let date = e;
    setEndDate(date);
    setIsRecords(false);
    setIsExport(false);
    let startSplit = startDate.split("-");
    let endSplit = date.split("-");
    let date1 = new Date(startSplit[0], startSplit[1], startSplit[2]);
    let date2 = new Date(endSplit[0], endSplit[1], endSplit[2]);
    if (date2 < date1) {
      endDateRef.current.value = "";
      toast.error("Ending date must be greater than starting date!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  //~.....................

  const orderList = async (page, limit, values, sortCol, sortDir) => {
    if (sortCol && sortCol != "true") {
      setDirDisabled(false);
      setSortCol(sortCol);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }

    if (sortCol != "true" && sortDir == "true") {
      setSortDirErr("Please select this");
      return;
    } else if (sortCol == "true" && sortDir != "true") {
      setSortColErr("Please select this");
      return;
    } else if (sortCol != "true" && sortDir != "true") {
      setSortColErr("");
      setSortDirErr("");
    }
    settableLoader(true);
    try {
      const responce = await AdminListService.get1099Report(
        page,
        limit,
        values,
        typeRef.current.value,
        dateRef.current.value,
        endDateRef.current.value,
        sortCol,
        sortDir
      );

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          // name: value.first_name + " " + value.last_name,
          first_name: value.first_name,
          last_name: value.last_name,
          email: value.email,
          city: value.city,
          state: value.state,
          address1: value.address1,
          zip: value.zip,
          sale_id: value.sale_id,
          sale_name: value.sale_name,
          points: value.points,
          spiff_amount: value.spiff_amount,
          created_at: value.created_at,
          points_date: value.points_date,
          id: value.id,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      // setResultData(res);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      settableData([]);
      settableLoader(false);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      orderList("1", limit, []);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delOrder = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleOrders(
            delId,
            admin_id
          );

          orderList(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const deletAllOrders = async () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Do You Want To Delete All Orders",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletAllOrders(admin_id);

          orderList(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const archiveAllOrders = async () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Do You Want To Archive All Orders",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.archiveAllOrders(admin_id);

          orderList(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Archived Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const archiveOrder = async (order_id) => {
    settableLoader(true);
    try {
      const response = await AdminListService.orderArchive(admin_id, order_id);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      settableLoader(false);
      setTimeout(() => {
        orderList(currentPage, limit, values);
      }, 1000);
    } catch (err) {
      settableLoader(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        orderList("1", limit, values);
      },
    });

  var singleSelections = "";
  var allSelections = "";

  const changeSelection = () => {
    $(".slect_all").prop("checked", false);

    let selectedRecords = $(".table tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join("+");
    singleSelections = selectedRecords;

    $(".export-selection").attr(
      "href",
      `https://purple-api.elitestacks.com.com/v1/admin/export-selected-1099/${selectedRecords}?time=${time}`
    );
  };

  const allSelect = () => {
    const selectAllCheckbox = $(".slect_all");
    const singleCheckboxes = $("input[name='single']:checkbox");

    selectAllCheckbox.prop("checked", !selectAllCheckbox.prop("checked"));
    singleCheckboxes.prop("checked", selectAllCheckbox.prop("checked"));

    const checkedVals = $(".select_one:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get();

    allSelections = checkedVals.join("+");

    $(".export-selection").attr(
      "href",
      `https://purple-api.elitestacks.com/v1/admin/export-selected-1099/${allSelections}`
    );
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        {tableLoader ? (
          <div className="card-body pt-0">
            <Skeleton count={11} height="50px" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="text-nowrap  table">
              <thead className="table-light">
                <tr>
                  <th
                    scope="col"
                    onClick={allSelect}
                    // className={getClassNamesFor("Select")}
                  >
                    <label htmlFor="Select">
                      <input
                        id="Select"
                        className="form-check-input slect_all"
                        type="checkbox"
                        onClick={allSelect}
                      />
                    </label>
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("id")}
                    className={getClassNamesFor("id")}
                  >
                    No
                  </th>

                  <th
                    scope="col"
                    onClick={() => requestSort("first_name")}
                    className={getClassNamesFor("first_name")}
                  >
                    User Info
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("sale_id")}
                    className={getClassNamesFor("sale_id")}
                  >
                    Sale Info
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("created_at")}
                    className={getClassNamesFor("created_at")}
                  >
                    Register Date
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("points_date")}
                    className={getClassNamesFor("points_date")}
                  >
                    Rewards Upload Date
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("Action")}
                    className={getClassNamesFor("Action")}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((el, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <input
                          className="form-check-input select_one"
                          name="single"
                          id={el.id}
                          value={el.id}
                          type="checkbox"
                          onChange={changeSelection}
                        />
                      </td>
                      <td className="align-middle">
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>

                      <td className="align-middle">
                        <table className="inner-table">
                          <tbody>
                            <tr>
                              <td>
                                <strong>First Name:</strong>
                              </td>
                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.first_name ? el.first_name : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Last Name:</strong>
                              </td>
                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.last_name ? el.last_name : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Email:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.email ? el.email : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>City:</strong>
                              </td>
                              <td>
                                <span className="text-control">{el.city}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>State:</strong>
                              </td>
                              <td>
                                <span className="text-control">{el.state}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Address1:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.address1 ? el.address1 : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Zip Code:</strong>
                              </td>
                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.zip ? el.zip : "N/A"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="align-middle">
                        <table className="inner-table">
                          <tbody>
                            <tr>
                              <td>
                                <strong>RSA RSA:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.sale_id ? el.sale_id : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Sales Person Name:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.sale_name ? el.sale_name : "N/A"}

                                  {/* <i class="icon ion-ios-checkmark"></i> */}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Reward Amount:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  $ {el.points ? el.points : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Total Reward Amount:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  $ {el.spiff_amount ? el.spiff_amount : "N/A"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>

                      <td className="align-middle">
                        {/* {moment(el.created_at).format("MM-DD-YYYY")} */}
                        {el.created_at !== "" &&
                          el.created_at !== "0000-00-00" &&
                          moment(el.created_at).format("MM-DD-YYYY")}
                      </td>
                      <td className="align-middle">
                        {el.points_date !== "" &&
                          el.points_date !== "0000-00-00" &&
                          moment(el.points_date).format("MM-DD-YYYY")}
                      </td>

                      <td className="align-middle" style={{ color: "#00b775" }}>
                        <img
                          className="thumb-up-icon"
                          src={thumbUp}
                          alt="icon"
                        />
                        {" Approved"}
                        <br />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  orderList(e, limit, values);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">1099 Report</div>
          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="mb-0">
                        Total Reports:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>

                    <div className="col-md-8 text-end">
                      <li className="dropdown">
                        <button
                          className="btn btn-primary back-blue dropdown-toggle viewRSA"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          id="RsaAction"
                        >
                          Action
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item export-selection"
                              name="export_selected"
                              href="javascript:void(0)"
                              style={{ cursor: "pointer" }}
                            >
                              Export Selected
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${process.env.REACT_APP_API_Link}admin/export-1099?time=${time}`}
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                            >
                              Export All
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Select Date</label>
                        <select
                          ref={typeRef}
                          className={`form-select ${selectGradient}
                            errors.datetype && touched.datetype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={(e) => changeDateColumn(e)}
                          name="datetype"
                          value={dateValue}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="register">Register Date</option>
                          <option value="rewards">Rewards Date</option>
                        </select>
                      </div>
                      {/* {dateError && (
                        <span className="text-danger">{dateError}</span>
                      )} */}
                    </div>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-4">
                        <label>{dateName} Start Date</label>
                        <div className="reacter-datepicker">
                          <input
                            type="date"
                            name="start"
                            ref={dateRef}
                            className={`form-control ${stGradient}`}
                            onChange={(e) => {
                              changeStartDate(e.target.value);
                            }}
                            placeholder="mm-dd-yyyy"
                            disabled={!isStartDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-field-box mb-4">
                        <label className="spiff-search">
                          {dateName} End Date
                        </label>
                        <div className="input-group">
                          <input
                            type="date"
                            name="end"
                            ref={endDateRef}
                            className={`form-control ${endGradient}`}
                            onChange={(e) => changeEndDate(e.target.value)}
                            placeholder="mm-dd-yyyy"
                            disabled={!isEndDate}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={(e) => orderList("1", limit)}
                            disabled={isRecords}
                            type="button"
                          >
                            Show records
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <a
                        className="btn btn-primary back-orange export-range"
                        onClick={isExport}
                        style={styles.range}
                        href={`${API_URL}admin/export-range-1099/${dateValue}/${startDate}/${endDate}`}
                      >
                        Export by range
                      </a>
                    </div>

                    <div className="col-12"></div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="first_name">First Name</option>
                          <option value="last_name">Last Name</option>
                          <option value="email">Email</option>
                          <option value="city">City</option>
                          <option value="state">State</option>
                          <option value="address1">Address1</option>
                          <option value="zip">Zip</option>
                          <option value="sale_id">RSA ID</option>
                          <option value="sale_name">Sales Person Name</option>
                          <option value="points">Reward Amount</option>
                          <option value="spiff_amount">
                            Total Reward Amount
                          </option>
                          <option value="created_at">Register Date</option>
                          <option value="points_date">
                            Reward Upload Date
                          </option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-4">
                      <div className="input-field-box mb-lg-0">
                        <label className="spiff-search">Search</label>
                        <div className="input-group">
                          <input
                            type={
                              values.fieldtype === "created_at" ||
                              values.fieldtype === "points_date"
                                ? "date"
                                : "text"
                            }
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            orderList("1", e.target.value, values)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Column</label>
                        <select
                          className="form-select"
                          value={sortCol}
                          name="sort_column"
                          onChange={(e) =>
                            orderList(
                              "1",
                              limit,
                              values,
                              e.target.value,
                              sortDir
                            )
                          }
                        >
                          <option value="">Select Column</option>
                          <option value="sale_id">RSA ID</option>
                          <option value="location">Location</option>
                          <option value="first_name">First Name</option>
                          <option value="last_name">Last Name </option>
                          <option value="email">Email</option>
                          <option value="address">Address</option>
                          <option value="state">State</option>
                          <option value="city">City</option>
                          <option value="zip">Zip</option>
                          <option value="spiff_amount">
                            Rewards Upload Date
                          </option>
                          <option value="created_at">Register Date</option>
                        </select>
                        {sortColErr && (
                          <span className="text-danger">{sortColErr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Direction</label>
                        <select
                          disabled={dirDisabled}
                          className="form-select"
                          value={sortDir}
                          onChange={(e) =>
                            orderList(
                              "1",
                              limit,
                              values,
                              sortCol,
                              e.target.value
                            )
                          }
                        >
                          <option value>Select Direction</option>
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        {sortDirErr && (
                          <span className="text-danger">{sortDirErr}</span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
