import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";
import $ from "jquery";

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";

import Header from "./Components/admin/includes/Header";

import Login from "./Components/admin/login/Login";

import { useDispatch, useSelector } from "react-redux";
import NotFound from "./Components/admin/includes/NotFound";

import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";
import Error429 from "./Components/admin/includes/Error429";
import Dashboard from "./Components/admin/Dashboard/Dashboard";
import jwtDecode from "jwt-decode";
import ForgotPass from "./Components/admin/login/ForgotPass";

import Profile from "./Components/admin/Dashboard/Profile";

import ManageWebsite from "./Components/admin/ManageWebsite/ManageWebsite";
// import ManageNewsletter from "./Components/admin/ManageWebsite/ManageNewsletter";
import PrivacyPolicy from "./Components/user/PrivacyPolicy";
import NoInternet from "./Components/user/NoInternet";
import Test from "./Components/admin/ManageDocument/Test";
import Eye from "./Components/admin/ManageDocument/Eye";
import UserDashboard from "./Components/user/Dashboard/UserDashboard";
import UserPrivacyPolicy from "./Components/user/Dashboard/UserPrivacyPolicy";
import ContactUs from "./Components/user/Dashboard/ContactUs";
import UserProfile from "./Components/user/Dashboard/UserProfile";
import UserLogin from "./Components/user/Dashboard/UserLogin/UserLogin";
import Register from "./Components/user/Dashboard/Register";
import ViewRsa from "./Components/admin/ManageRsa/ViewRsa";
import ViewReports from "./Components/admin/1099Reports/ViewReports";
import ForgotPassword from "./Components/user/Dashboard/UserLogin/ForgotPassword";
import TermConditions from "./Components/user/Dashboard/UserLogin/TermCondintion";
import UserHeader from "./Components/user/Dashboard/includes/UserHeader";
import ViewRewards from "./Components/admin/ManageRewards/ViewRewards";
import ViewSpiffs from "./Components/user/Dashboard/ViewSpiffs";
import PrivacyStatement from "./Components/user/PrivacyStatement";
import EditUser from "./Components/admin/ManageRsa/EditUser";
import AddRewards from "./Components/admin/ManageRewards/AddRewards";
import ViewRewards2 from "./Components/admin/ManageRewards/ViewRewards2";

function App() {
  const navigate = useNavigate();
  const locat = useLocation();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, accessToken } = state;
  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <NoInternet>
      <ToastContainer />
      {/* ADMIN HEADER */}
      {locat.pathname !== "/admin/login" &&
      locat.pathname !== "/admin/" &&
      locat.pathname !== "/admin" &&
      locat.pathname !== "/dashboard" &&
      locat.pathname !== "/" &&
      locat.pathname !== "/forgot-password" &&
      locat.pathname !== "/admin/forgot-pass" &&
      locat.pathname !== "/term-conditions" &&
      locat.pathname !== "/privacy-statement" &&
      locat.pathname !== "/register" &&
      locat.pathname !== "/register/" &&
      locat.pathname !== "/user-profile" &&
      locat.pathname !== "/user-profile/" &&
      locat.pathname !== "/contact-us" &&
      locat.pathname !== "/contact-us/" &&
      locat.pathname !== "/privacy-policy" &&
      locat.pathname !== "/privacy-policy/" &&
      locat.pathname !== "/privacyStatement" &&
      locat.pathname !== "/privacyStatement/" &&
      locat.pathname !== "/privacyStatement/" &&
      locat.pathname !== "/view-spiffs" &&
      locat.pathname !== "/view-spiffs/" &&
      locat.pathname !== "/privacy-statement/" ? (
        <Header />
      ) : null}

      {/* USER HEADER */}
      {locat.pathname !== "/" &&
      locat.pathname !== "/admin/login" &&
      locat.pathname !== "/admin/dashboard" &&
      locat.pathname !== "/admin/profile" &&
      locat.pathname !== "/admin/manage-website" &&
      locat.pathname !== "/admin/manage-newsletter" &&
      locat.pathname !== "/admin/add-reward" &&
      locat.pathname !== "/admin/manage-reward" &&
      locat.pathname !== "/admin/1099-report" &&
      locat.pathname !== "/admin/view-rsa" &&
      locat.pathname !== "/forgot-password" &&
      locat.pathname !== "/admin/forgot-pass" &&
      locat.pathname !== "/privacy-statement" &&
      locat.pathname !== "/privacy-statement/" &&
      locat.pathname !== "/privacyStatement" &&
      locat.pathname !== "/privacyStatement/" &&
      locat.pathname !== "/term-conditions" &&
      locat.pathname !== "/term-conditions/" &&
      locat.pathname !== "/admin/edit-user/:id" &&
      locat.pathname !== "/admin/edit-user/:id" &&
      locat.pathname !== "/register" &&
      locat.pathname !== "/register" ? (
        <UserHeader />
      ) : null}

      {/* FIXME Routes */}
      <Routes>
        <Route path="/header" element={<Header />} />
        <Route path="/" element={<UserLogin />} />
        <Route path="/register" element={<Register />} />

        <Route
          path="/dashboard"
          element={accessToken ? <UserDashboard /> : <UserLogin />}
        />
        <Route
          path="/view-spiffs"
          element={accessToken ? <ViewSpiffs /> : <UserLogin />}
        />
        <Route
          path="/contact-us"
          element={accessToken ? <ContactUs /> : <UserLogin />}
        />
        <Route
          path="/user-profile"
          element={accessToken ? <UserProfile /> : <UserLogin />}
        />
        <Route
          path="/privacy-policy"
          element={accessToken ? <PrivacyPolicy /> : <UserLogin />}
        />
        <Route
          path="/privacyStatement"
          element={accessToken ? <PrivacyStatement /> : <UserLogin />}
        />

        <Route path="/privacy-statement" element={<UserPrivacyPolicy />} />
        <Route path="/term-conditions" element={<TermConditions />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/*// ~ ADMIN panel Side */}

        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgot-pass" element={<ForgotPass />} />
        <Route
          path="/admin/dashboard"
          element={adminAccessToken ? <Dashboard /> : <Login />}
        />
        <Route
          path="/admin/profile"
          element={adminAccessToken ? <Profile /> : <Login />}
        />
        <Route
          path="/admin/manage-website"
          element={adminAccessToken ? <ManageWebsite /> : <Login />}
        />
        {/* <Route
          path="/admin/manage-newsletter"
          element={adminAccessToken ? <ManageNewsletter /> : <Login />}
        /> */}
        <Route
          path="/admin/import-test"
          element={adminAccessToken ? <Test /> : <Login />}
        />
        <Route
          path="/admin/password-eye"
          element={adminAccessToken ? <Eye /> : <Login />}
        />

        <Route
          path="/admin/add-reward"
          element={adminAccessToken ? <AddRewards /> : <Login />}
        />
        <Route
          path="/admin/manage-reward"
          element={adminAccessToken ? <ViewRewards /> : <Login />}
        />
        <Route
          path="/admin/manage-reward2"
          element={adminAccessToken ? <ViewRewards2 /> : <Login />}
        />
        <Route
          path="/admin/view-rsa"
          element={adminAccessToken ? <ViewRsa /> : <Login />}
        />
        <Route
          path="/admin/edit-user/:id"
          element={adminAccessToken ? <EditUser /> : <Login />}
        />
        <Route
          path="/admin/1099-report"
          element={adminAccessToken ? <ViewReports /> : <Login />}
        />

        <Route path="/error-429" element={<Error429 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {locat.pathname.match(/\/{2,}/) ? (
        <div className="fix-white"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
