import * as Yup from "yup";

export const signImSchema = Yup.object({
  username: Yup.string().required("Enter UserName"),
  password: Yup.string().required("Enter Password"),
});
export const usersignImSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Enter Email"),
  password: Yup.string().required("Enter Password"),
});
export const forgot = Yup.object({
  email: Yup.string().email("Invalid Email").required("Enter Email"),
});

export const contactSchema = Yup.object({
  subject: Yup.string().min(2).required("Please enter the Subject"),
  name: Yup.string().min(2).required("Please enter the Name"),
  // phone: Yup.number()
  //   .typeError("Please enter numbers only")
  //   .required("Please enter the Phone"),
  phone: Yup.string()
  .matches(/^[0-9]{10}$/, "Please enter a 10-digit phone number")
  .required("Please enter the Phone"),
  email: Yup.string().min(2).required("Please enter the Email"),
  contactBack: Yup.string().min(2).required("Please select the type"),
  question: Yup.string().min(2).required("Please enter the Question"),
});
export const updateProfile = Yup.object({
  email: Yup.string()
    .email("Invalid E-mail")
    .required("Please enter the e-mail"),
  old_password: Yup.string().when({
    is: (old_password) => old_password,
    then: Yup.string().required("Please enter the current password"),
    otherwise: Yup.string().notRequired(),
  }),
  password: Yup.string().when("old_password", {
    is: (old_password) => old_password,
    then: Yup.string().required("Please enter the new password"),
    otherwise: Yup.string().notRequired(),
  }),

  confirm_password: Yup.string()
    .when("password", {
      is: (password) => password,
      then: Yup.string().required("Please verify the new password"),
      otherwise: Yup.string().notRequired(),
    })
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const websiteChange = Yup.object({
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
  // phone: Yup.string()
  //   .matches(/^[0-9- ]+$/, "Invalid phone number")
  //   .min(12, "The phone must contain 12 characters.")
  //   .required("Please enter the phone"),
});
export const contactUsValidate = Yup.object({
  subject: Yup.string().required("Please select the subject"),
  name: Yup.string().required("Please enter the name"),
  phone: Yup.string()
    .matches(/^[0-9- ]+$/, "Invalid phone number")
    .min(12, "The phone must contain 12 characters.")
    .required("Please enter the phone"),
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
  contactBack: Yup.string().required("Please select the contact me"),
  question: Yup.string().required("Please enter the question"),
});
const phoneRegExp = /^([0-9]{10})$/gm;
export const signUpSchema = Yup.object({
  email: Yup.string().email().required("Please enter the Email"),
  fname: Yup.string().min(2).required("Please enter the First Name"),
  lname: Yup.string().min(2).required("Please enter the Last Name"),
  ssn: Yup.string()
    .min(11, "Please enter valid SSN")
    .max(11, "Please enter complete SSN")
    .required("Please enter the SSN"),

  address1: Yup.string().min(2).required("Please enter the Address1"),
  state_id: Yup.string().required("Please select the State"),
  city_id: Yup.number().required("Please enter the City"),
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters long")
    .required("Please enter the Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      "Please enter atleast 1 uppercase,1 lowercase and 1 digit."
    ),
  confirm_password: Yup.string()
    .required("Please enter the Password again")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  zipcode: Yup.string().min(5).required("Please enter the Zip Code"),
  phone: Yup.string()
    .required("Please enter the Phone")
    .matches(phoneRegExp, "Please enter at least 10 digits"),
  signature: Yup.string()
    .min(2, "Signature must be at least 2 characters")
    .required("Please enter the Signature"),
  iAgree: Yup.bool().oneOf([true], "Please accept our agreement"),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You need to agree  the terms and conditions"
  ),
});
export const updateNewsleter = Yup.object({
  subject: Yup.string().required("Please enter the subject"),
  body: Yup.string().required("Please enter in the body"),
});

export const updateGift = Yup.object({
  color: Yup.string().required("Please enter the color"),
  image: Yup.string().required("Please select the image "),
  // description: Yup.string().required("Please enter the description "),
});
export const updateSize = Yup.object({
  size: Yup.string().required("Please enter the size"),
});

export const newGiftAdd = Yup.object({
  file: Yup.string().required("Please select the file"),
});

export const newSizeAdd = Yup.object({
  size: Yup.string().required("Please enter the size"),
  // description: Yup.string().required("Please enter the description "),
  // image: Yup.string().required("Please select the image "),
});

export const quizSearch = Yup.object({
  fieldtype: Yup.string().required("Please Select a Column"),
  searchval: Yup.string().required("Please fill the search field"),
});
export const rewardSearchFilters = Yup.object({
  fieldtype: Yup.string().required("Please Select a Column"),
  searchval: Yup.string().required("Please fill the search field"),
});

export const addEmailFun = Yup.object({
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
});

export const addLogoFun = Yup.object({
  image: Yup.string().required("Please select the logo"),
});

export const updateValidateRsa = Yup.object({
  ssn: Yup.string()
    .min(11, "Please enter valid SSN")
    .max(11, "Please enter complete SSN")
    .required("Please enter the SSN"),
  phone: Yup.string()
    .required("Please enter the Phone")
    .matches(phoneRegExp, "Please enter at least 10 digits"),
  first_name: Yup.string()
    .min(3, "The first name field must be at least 3 characters.")
    .max(50, "The first name field must not greater then 50 characters.")
    .required("The first name field is required.	"),
  last_name: Yup.string()
    .min(3, "The last name field must be at least 3 characters.")
    .max(50, "The last name field must not greater then 50 characters.")
    .required("The last name field is required."),
  email: Yup.string()
    .email("Invalid E-mail")
    .required("Please enter the e-mail"),
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters long")
    .required("Please enter the Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      "Please enter atleast 1 uppercase,1 lowercase and 1 digit."
    ),
  address: Yup.string().min(2).required("Please enter the Address1"),
  state: Yup.string()
    .min(1, "The state field must be at least 1 characters.")
    .max(20, "The state field must not greater then 20 characters.")
    .required("The state field is required."),
  city: Yup.string()
    .min(3, "The city field must be at least 2 characters.")
    .max(30, "The city field must not greater then 30 characters.")
    .required("The city field is required."),
  zip: Yup.string().required("The zip field is required."),
});

export const importDocument = Yup.object({
  size: Yup.string().required("Please select the size option"),
  color: Yup.string().required("Please select the color option"),
  file: Yup.string().required("Please select the file"),
});
