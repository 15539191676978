import { useFormik } from "formik";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminListService from "../../../../services/admin-list.service";
import "./UserLogin.css";
import Logo from "../../../../Images/logo.webp";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../../Redux/index";
import { Helmet } from "react-helmet";
import { signImSchema, usersignImSchema } from "../../../../schema";
import ReactLoading from "react-loading";
import $ from "jquery";
import zxcvbn from "zxcvbn";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import ToastLogin from "../includes/ToastLogin";
import { ToastContainer, toast } from "react-toastify";
import LoginFooter from "./LoginFooter";
import TopBar from "./TopBar";
import UserService from "../../../../services/user.service";

export default function UserLogin() {
  const state = useSelector((state) => state.stateVals);
  const { accessToken } = state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState(0);

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  useEffect(() => {
    if (accessToken) {
      navigate("/dashboard", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  const [values1, setValues1] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues1({
      ...values1,
      showPassword: !values1.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const showToastMessage = (message, type = "success", options = {}) => {
    const defaultOptions = {
      position:
        type === "success"
          ? toast.POSITION.BOTTOM_CENTER
          : toast.POSITION.BOTTOM_CENTER,
      autoClose: 1500, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type === "success") {
      toast.success(message, toastOptions);
    } else if (type === "error") {
      toast.error(message, toastOptions);
    }
  };

  const login = async (values) => {
    setLoading(true);
    try {
      const response = await UserService.login(values);
      userActions.logIn(
        {
          accessToken: response.data.access_token,
          uType: response.data.user.user_type,
          id: response.data.user.id,
          uName: response.data.user.username,
          name:
            response.data.user.first_name + " " + response.data.user.last_name,
        },
        "login"
      );

      navigate("/dashboard", { replace: true });
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        toast.error("Username or Password is invalid !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 403) {
        setLoading(false);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);

        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: usersignImSchema,
      onSubmit: (values, action) => {
        login(values);
      },
    });

  const handlePasswordChange = (data) => {
    const result = zxcvbn(data.password);

    // Custom check for minimum 2 lowercase, 2 uppercase, 2 numbers, and 2 special characters
    const lowercaseCount = (data.password.match(/[a-z]/g) || []).length;
    const uppercaseCount = (data.password.match(/[A-Z]/g) || []).length;
    const digitCount = (data.password.match(/[0-9]/g) || []).length;
    const specialCharCount = (
      data.password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []
    ).length;

    const lowerCount = lowercaseCount >= 2 ? 1 : 0;
    const upperCount = uppercaseCount >= 2 ? 1 : 0;
    const digitsCount = digitCount >= 2 ? 1 : 0;
    const specialCount =
      specialCharCount >= 2 ? 2 : specialCharCount == 1 ? 1 : 0;
    const totalCount = lowerCount + upperCount + digitsCount + specialCount;

    values.password = data.password;
    setPasswordStrength(totalCount);
  };

  return (
    <div className="login-form">
      {/* <TopBar /> */}
      <Helmet>
        <title>User Panel</title>
      </Helmet>
      <div className="container-login100">
        <div className="wrap-login100">
          <img src={Logo} alt="" className="login-logo" />
          {/* <span className="login100-form-title"> Account Login </span> */}

          <div className="inner-heading">
            <div className="container">
              <h1 className="text-center">Purple Rewards</h1>
            </div>
          </div>
          <form
            className="login100-form validate-form"
            onSubmit={handleSubmit}
            noValidate
          >
            <div
              className={`wrap-input100 validate-input ${
                errors.email && touched.email ? "alert-validate" : ""
              } `}
              data-validate="Enter Email"
              id="Email"
            >
              <Input
                className={`input100 ${values.email !== "" ? "has-val" : ""}`}
                type="text"
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  $("#Email").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#Email").addClass("input-focus");
                }}
                name="email"
                value={values.email || ""}
                placeholder="Email"
              />
              <span
                className="focus-input100"
                data-placeholder="&#xF0E0;"
              ></span>
            </div>

            <div
              className={`wrap-input100 validate-input ${
                errors.password && touched.password ? "alert-validate" : ""
              } `}
              data-validate="Enter Password"
              id="password"
            >
              <Input
                className={`input100 ${
                  values.password !== "" ? "has-val" : ""
                }`}
                type={values1.showPassword ? "text" : "password"}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  $("#password").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#password").addClass("input-focus");
                }}
                name="password"
                value={values.password || ""}
                placeholder="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values1.showPassword
                        ? values.password.length > 0 && <Visibility />
                        : values.password.length > 0 && <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <span
                className="focus-input100"
                data-placeholder="&#xf023;"
              ></span>
              {/* <Link to="/forgot-password">Forgot Password</Link> */}
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" type="submit">
                Login
              </button>
            </div>
            <div className="container-login100-form-btn forgot">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
            <div className="container-login100-form-btn">
              <p className="message">
                Not registered? <Link to="/register">Create an account</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <LoginFooter />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#432b8d"
          className="bar-loader"
        />
      )}
    </div>
  );
}
