import React from "react";
import ToTop from "./ToTop";

export default function Footer() {
  return (
    <>
      <footer className="user-footer-main">
        <div className="footer-content">
          <span>
            Copyright &copy; {new Date().getFullYear()}{" "}
            <span className="fotr-title">
              {process.env.REACT_APP_API_SITE_TITLE}
            </span>
            . All rights reserved.
          </span>
          <a href="/privacyStatement" className="privacy-button" target="_blank">Privacy Statement</a>
        </div>
      </footer>
      <ToTop />
    </>
  );
}
