import React, { useEffect, useState } from "react";

import { useMask } from "@react-input/mask";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { updateValidateRsa } from "../../../schema";
import moment from "moment";
import Select from "react-select";

export default function EditUser() {
  const { id } = useParams();
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [errorsServer, setErrorsServer] = useState([]);
  const [disable, setDisable] = useState(false);
  const [ssnerror, setSSNerror] = useState("");
  const [ssn, setSsn] = useState("");
  const [phone, setPhone] = useState("");
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [created_at, setCreated_at] = useState("");
  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [userCityLoader, setUserCityLoader] = useState(false);

  const reduxState = useSelector((reduxState) => reduxState.stateVals);
  const { adminAccessToken, adminId } = reduxState;
  const navigate = useNavigate();
  var startState = "";
  const ssnRef = useMask({ mask: "___-__-____", replacement: { _: /\d/ } });
 
  const checkSSN = async (e) => {
    if (values.ssn !== "" && values.ssn.length >= 11) {
      values["id"] = adminId;
      try {
        const response = await AdminListService.validateSSNAdmin(values);

        setDisable(false);
        setSSNerror("");
      } catch (err) {
        if (err.response.status === 409) {
          setDisable(true);
          setSSNerror(err.response.data.response);
        } else {
          setDisable(false);
        }
      }
    } else {
      setDisable(false);

      setSSNerror("");
    }
  };

  const updateRsa = async (values) => {
    setFormSubmiting(true);

    try {
      values["updated_by"] = adminId;
      const response = await AdminListService.updateRsaList(
        id,
        values,
        adminId
      );

      setFormSubmiting(false);
      toast.success("Record Updated!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        navigate("/admin/view-rsa", { replace: true });
      }, 1000);
    } catch (err) {
      setFormSubmiting(false);
      if (err.response.status === 422) {
        setErrorsServer((prevErrors) => ({
          ...prevErrors,
          first_name: err?.response?.data?.first_name?.[0] || "",
          last_name: err?.response?.data?.last_name?.[0] || "",
          email: err?.response?.data?.email?.[0] || "",
          phone: err?.response?.data?.phone?.[0] || "",
          address: err?.response?.data?.address?.[0] || "",
          ssn: err?.response?.data?.ssn?.[0] || "",
          zip: err?.response?.data?.zip?.[0] || "",
          state_id: err?.response?.data?.state_id?.[0] || "",
          city_id: err?.response?.data?.city_id?.[0] || "",
          signature: err?.response?.data?.signature?.[0] || "",
          password: err?.response?.data?.password?.[0] || "",
        }));
      } else if (err.response.status === 409) {
        setErrorsServer((prevErrors) => ({
          ...prevErrors,
          ssn: err?.response?.data?.response || "",
        }));
      } else {
        toast.error("Some thing went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await AdminListService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setUserState([...results]);
    };

    getUserState();

    const getResultData = async () => {
      setFormSubmiting(true);
      try {
        const response = await AdminListService.getUserById(id);

        const { data } = response;
        const { response: res } = data;
        setSsn(res.ssn);
        setPhone(res.phone);
        setFirst_Name(res.first_name);
        setLast_Name(res.last_name);
        setPassword(res.password);
        setEmail(res.email);
        setAddress(res.address1);
        setState(res.state_id);
        ///////////////////////////

        setUserCityLoader(true);
        const { data: innerData } = await AdminListService.getCityByStateId(
          res.state_id
        );
        const { response: InnerRes } = innerData;
        const InnerResults = [];
        InnerRes.map((value) => {
          InnerResults.push({
            label: value.city,
            value: value.id,
          });
        });
        setUserCity([...InnerResults]);
        setUserCityLoader(false);
        //////////////////////////
        // startState = res.state_id;
        setCity(res.city_id);
        setZip(res.zip);
        // {moment(res.created_at).format("MM-DD-YYYY")}
        // setCreated_at(moment(res.created_at).format("MM-DD-YYYY"));
        setCreated_at(
          res.created_at &&
            res.created_at !== "" &&
            res.created_at !== " " &&
            res.created_at !== "0000-00-00" &&
            moment(res.created_at).format("YYYY-MM-DD")
        );

        setFormSubmiting(false);
      } catch (err) {
        setFormSubmiting(false);
      }
    };

    getResultData();
    // console.log(`id=${startState}`);
    // const getUserCityStart = async () => {
    //   setUserCityLoader(true);
    //   const { data } = await AdminListService.getCityByStateId(5);
    //   const { response: res } = data;
    //   const results = [];
    //   res.map((value) => {
    //     results.push({
    //       label: value.city,
    //       value: value.id,
    //     });
    //   });
    //   setUserCity([...results]);
    //   setUserCityLoader(false);
    // };

    // getUserCityStart();
  }, []);

  const changeUserCity = (state) => {
    setUserCity([]);

    const getUserCity = async () => {
      setUserCityLoader(true);
      const { data } = await AdminListService.getCityByStateId(state);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.city,
          value: value.id,
        });
      });
      setUserCity([...results]);
      setUserCityLoader(false);
    };

    if (state !== "") {
      getUserCity();
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    setErrors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ssn: ssn,
      phone: phone,
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      address: address,
      state: state,
      city: city,
      zip: zip,
      created_at: created_at,
    },

    validationSchema: updateValidateRsa,
    onSubmit: (values) => {
      updateRsa(values);
    },
  });

  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">Update RSA</div>

          <form className="mt-4 row" onSubmit={handleSubmit} noValidate>
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Edit Your RSA</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          SSN{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="XXX-XX-XXXX"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyUp={checkSSN}
                          name="ssn"
                          value={values.ssn || ""}
                          ref={ssnRef}
                        />
                        {errors.ssn && touched.ssn ? (
                          <span className="text-danger">{errors.ssn}</span>
                        ) : errorsServer.ssn ? (
                          <span className="text-danger">
                            {errorsServer.ssn}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Phone No{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="phone"
                          value={values.phone || ""}
                        />
                        {errors.phone && touched.phone ? (
                          <span className="text-danger">{errors.phone}</span>
                        ) : errorsServer.phone ? (
                          <span className="text-danger">
                            {errorsServer.phone}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          First Name{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="first_name"
                          value={values.first_name || ""}
                          disabled={true}
                        />
                        {errors.first_name && touched.first_name ? (
                          <span className="text-danger">
                            {errors.first_name}
                          </span>
                        ) : errorsServer.first_name ? (
                          <span className="text-danger">
                            {errorsServer.first_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Last Name{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="last_name"
                          value={values.last_name || ""}
                          disabled={true}
                        />
                        {errors.last_name && touched.last_name ? (
                          <span className="text-danger">
                            {errors.last_name}
                          </span>
                        ) : errorsServer.last_name ? (
                          <span className="text-danger">
                            {errorsServer.last_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Email{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          value={values.email || ""}
                        />
                        {errors.email && touched.email ? (
                          <span className="text-danger">{errors.email}</span>
                        ) : errorsServer.email ? (
                          <span className="text-danger">
                            {errorsServer.email}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Password{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="password"
                          value={values.password || ""}
                        />
                        {errors.password && touched.password ? (
                          <span className="text-danger">{errors.password}</span>
                        ) : errorsServer.password ? (
                          <span className="text-danger">
                            {errorsServer.password}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Address{" "}
                          <span className="text-danger error-message">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="address"
                          value={values.address || ""}
                        />
                        {errors.address && touched.address ? (
                          <span className="text-danger">{errors.address}</span>
                        ) : errorsServer.address ? (
                          <span className="text-danger">
                            {errorsServer.address}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            City{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="city"
                            value={values.city || ""}
                          />
                          {errors.city && touched.city ? (
                            <span className="text-danger">{errors.city}</span>
                          ) : errorsServer.city ? (
                            <span className="text-danger">
                              {errorsServer.city}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            State{" "}
                            <span className="text-danger error-message">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="state"
                            value={values.state || ""}
                          />
                          {errors.state && touched.state ? (
                            <span className="text-danger">{errors.state}</span>
                          ) : errorsServer.state ? (
                            <span className="text-danger">
                              {errorsServer.state}
                            </span>
                          ) : null}
                        </div>
                      </div> */}

                    <div className="col-lg-6">
                      <div className="input-field-box">
                        <div
                          className={`form-floating ${
                            errors.state && touched.state ? "is-danger" : ""
                          }`}
                        >
                          <Select
                            classNamePrefix="react-select"
                            className={`select_1 dropdown ${
                              errors.state && touched.state
                                ? "error-select-search"
                                : ""
                            }`}
                            // placeholder={"Select State"}
                            // placeholder={
                            //   values.state !== ""
                            //     ? userState.find(
                            //         (state) => state.value === values.state
                            //       )?.label
                            //     : "Select State"
                            // }
                            options={userState}
                            // value={values.state || ""}
                            value={userState.find(
                              (state) => state.value === values.state
                            )}
                            isSearchable={true}
                            name="state"
                            onChange={(selectedOption) => {
                              setFieldValue("city_id", "");

                              if (selectedOption) {
                                setFieldValue("state", selectedOption.value);
                                changeUserCity(selectedOption.value);
                              } else {
                                setFieldValue("state", "");
                              }
                            }}
                            onBlur={() => setFieldTouched("state", true)}
                          />
                          <label className="select-label">
                            State
                            <span className="text-danger"> *</span>
                          </label>

                          {errors.state && touched.state ? (
                            <span className="text-danger">{errors.state}</span>
                          ) : errorsServer.state ? (
                            <span className="text-danger">
                              {errorsServer.state}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-field-box">
                        {userCityLoader ? (
                          <h5 className="card-title placeholder-glow">
                            <span
                              className="placeholder col-12"
                              style={{
                                height: "58px",
                                marginTop: "4px",
                              }}
                            ></span>
                          </h5>
                        ) : (
                          <div
                            className={`form-floating ${
                              errors.city && touched.city ? "text-danger" : ""
                            }`}
                          >
                            <Select
                              className={
                                errors.city && touched.city
                                  ? "error-select-search"
                                  : ""
                              }
                              // placeholder={"Select City"}
                              // placeholder={
                              //   values.city !== ""
                              //     ? userCity.find(
                              //         (city) => city.value === values.city
                              //       )?.label
                              //     : "Select City"
                              // }
                              options={userCity}
                              // value={values.city || ""}
                              value={userCity.find(
                                (city) => city.value === values.city
                              )}
                              isSearchable={true}
                              name="city"
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  setFieldValue("city", selectedOption.value);
                                } else {
                                  setFieldValue("city", "");
                                }
                              }}
                              onBlur={() => setFieldTouched("city", true)}
                            />
                            <label className="select-label">
                              City <span className="text-danger">*</span>
                            </label>
                            {errors.city && touched.city ? (
                              <span className="text-danger">{errors.city}</span>
                            ) : errorsServer.city ? (
                              <span className="text-danger">
                                {errorsServer.city}
                              </span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Zip Code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="zip"
                          value={values.zip || ""}
                        />
                        {errors.zip && touched.zip ? (
                          <span className="text-danger">{errors.zip}</span>
                        ) : errorsServer.zip ? (
                          <span className="text-danger">
                            {errorsServer.zip}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>Register Date</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="created_at"
                          value={values.created_at || ""}
                          disabled={true}
                        />
                        <span className="text-danger">
                          (MM/DD/YYYY) eg : 12/25/2014
                        </span>
                        {errors.created_at && touched.created_at ? (
                          <span className="text-danger">
                            {errors.created_at}
                          </span>
                        ) : errorsServer.created_at ? (
                          <span className="text-danger">
                            {errorsServer.created_at}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary my-2"
                      type="submit"
                      onClick={handleButtonClick}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}