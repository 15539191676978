import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useFormik } from "formik";
import { useMask } from "@react-input/mask";
import {
  contactSchema,
  contactUsValidate,
  websiteChange,
} from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import Footer from "../../user/Dashboard/includes/Footer";
import UserService from "../../../services/user.service";
import UserHeader from "./includes/UserHeader";

export default function ContactUs() {
  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state.stateVals);
  const { accessToken, id } = state;
  const navigate = useNavigate();
  const [errorsServer, setErrorsServer] = useState([]);

  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [question, setQuestion] = useState("");

  // const inputRef = useMask({
  //   mask: "___-___-____",
  //   replacement: { _: /\d/ },
  //   showMask: true,
  //   separate: true,
  // });

  // function onChange(value) {
  //   setErrorsCap(value);
  //   if (value === null) {
  //     setErrorsCapMsg("Captcha is not verified");
  //   } else {
  //     setErrorsCapMsg("");
  //   }

  // }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        subject: subject,
        name: name,
        phone: phone,
        email: email,
        contactBack: contact,
        question: question,
      },
      validationSchema: contactSchema,
      onSubmit: (values, action) => {

        submitContact(values, action);
        // if (errorsCap !== null) {
        //   action.resetForm();
        //   setErrorsCapMsg("");
        // } else {
        //   setErrorsCapMsg("Captcha is not verified");
        // }
      },
    });

  // FIXME
  const submitContact = async (values, action) => {
    setLoading(true);
    try {
      const result = await UserService.contact(values);
      const { response } = result.data;
      toast.success(response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        setErrorsServer((prevErrors) => ({
          ...prevErrors,
          subject: err?.response?.data?.subject?.[0] || "",
          name: err?.response?.data?.name?.[0] || "",
          email: err?.response?.data?.email?.[0] || "",
          phone: err?.response?.data?.phone?.[0] || "",
          contactBack: err?.response?.data?.contact?.[0] || "",
          question: err?.response?.data?.question?.[0] || "",
        }));
      } else if (err.response.status === 409) {
        setErrorsServer((prevErrors) => ({
          ...prevErrors,
          ssn: err?.response?.data?.response || "",
        }));
      } else {
        toast.error("Some thing went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  // const verifyCallback = (response) => {
  //   let errorsCap = {};
  //   if (response) {
  //   } else {
  //     return false;
  //   }
  // };

  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design">
            <div className="user-heading-sectiom">
              CONTACT <span className="p-color">US</span>
              <div className="clearfix"></div>
            </div>

            <div className="row">
              <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white  py-3 card-header">
                    <h3 className="contact-title mt-2">AWARD HEADQUARTERS</h3>
                    <div className="col-12">
                      <div
                        className="alert-content-glass alert alert-primary d-flex align-items-center font-17 "
                        role="alert"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          fill="#052c65"
                          aria-label="Warning:"
                          style={{ marginTop: "-2px" }}
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                          {" "}
                          <strong>Info!</strong> Questions, comments, concerns,
                          suggestions ... if it's on your mind, we want to hear
                          from you!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="row" onSubmit={handleSubmit} noValidate>
                      <h4 className="contact-title mb-4">
                        WHAT WE CAN DO FOR YOU?
                      </h4>
                      <div className="col-md-4">
                        <div className="input-field-box mb-lg-0">
                          <label>
                            Subject <span className="text-danger">*</span>
                          </label>
                          <select
                            className={`form-select ${
                              errors.subject && touched.subject
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="subject"
                            value={values.subject || ""}
                            required
                          >
                            <option value=""> Select Subject</option>
                            <option value="By phone, ASAP!">
                              By phone, ASAP!
                            </option>
                            <option value="By phone, morning EST">
                              By phone, morning EST
                            </option>
                            {/* <option value="description">Description </option> */}
                          </select>
                          {errors.subject && touched.subject ? (
                            <span className="text-danger">
                              {errors.subject}
                            </span>
                          ) : null}
                          {!errors.subject && errorsServer.subject && (
                            <span className="text-danger">
                              {errorsServer.subject}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box">
                          <label>
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="name"
                            value={values.name || ""}
                          />
                          {errors.name && touched.name ? (
                            <span className="text-danger">{errors.name}</span>
                          ) : null}
                          {!errors.name && errorsServer.name && (
                            <span className="text-danger">
                              {errorsServer.name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box mb-0">
                          <label>
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="phone"
                            value={values.phone || ""}
                            // ref={inputRef}
                          />
                          {errors.phone && touched.phone ? (
                            <span className="text-danger">{errors.phone}</span>
                          ) : null}
                          {!errors.phone && errorsServer.phone && (
                            <span className="text-danger">
                              {errorsServer.phone}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box">
                          <label>
                            E-mail <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="E-mail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            value={values.email || ""}
                          />
                          {errors.email && touched.email ? (
                            <span className="text-danger">{errors.email}</span>
                          ) : null}
                          {!errors.email && errorsServer.email && (
                            <span className="text-danger">
                              {errorsServer.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box mb-lg-0">
                          <label>
                            Contact Me Back{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className={`form-select ${
                              errors.contactBack && touched.contactBack
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="contactBack"
                            value={values.contactBack || ""}
                            required
                          >
                            <option value=""> Contact Me Back</option>
                            <option value="By phone, ASAP!">
                              By phone, ASAP!
                            </option>
                            <option value="By phone, morning EST">
                              By phone, morning EST
                            </option>
                            <option value="By phone, afternoon EST">
                              By phone, afternoon EST
                            </option>
                            <option value="By phone, evening EST">
                              By phone, evening EST
                            </option>
                            <option value="By email only!">
                              By email only!
                            </option>
                            {/* <option value="description">Description </option> */}
                          </select>
                          {errors.contactBack && touched.contactBack ? (
                            <span className="text-danger">
                              {errors.contactBack}
                            </span>
                          ) : null}
                          {!errors.contactBack && errorsServer.contactBack && (
                            <span className="text-danger">
                              {errorsServer.contactBack}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-field-box">
                          <label>
                            Question <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Question"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="question"
                            value={values.question || ""}
                          />
                          {errors.question && touched.question ? (
                            <span className="text-danger">
                              {errors.question}
                            </span>
                          ) : null}
                          {!errors.question && errorsServer.question && (
                            <span className="text-danger">
                              {errorsServer.question}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary mt-3" type="submit">
                          Submit Request
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </main>
      </div>
      <Footer />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}
