import React from "react";
import Footer from "../user/Dashboard/includes/Footer";
import { Link } from "react-router-dom";
import Logo from "../../Images/logo.webp";

export default function PrivacyStatement() {
  return (
    <>
      <div className="wrapper">
        <div className="container">
          <main className="page-content customer-contnent">
            <div className="user-main-design">
              <header className="main-header">
                <nav className="navbar navbar-expand-lg navbar-light flex-column">
                  <Link className="navbar-brand" to="/">
                    <img src={Logo} className="login-logo" alt="" />
                  </Link>
                </nav>
              </header>
              <div className="user-heading-sectiom">
                PRIVACY <span className="p-color">STATEMENT</span>
                <div className="clearfix"></div>
              </div>

              <div className="mt-5 row">
                <div className="col-md-12 col-12">
                  <div className="card dashboard-cards">
                    <div className="bg-white  py-3 card-header">
                      <div className="col-12">
                        <div className="p-policy">
                          <h4>ELITE REWARDS PRIVACY STATEMENT</h4>
                          <b>Site Security Features</b>
                          <p>
                            Elite Rewards (ER) realizes how important security
                            is to our clients and to their participants, so
                            we’ve taken a number of steps to enhance the
                            protection of personal or confidential information
                            sent to or from ER or in accessing information
                            resident at ER. This includes username, password,
                            and any debit card information. First, we require
                            unique usernames and password establishment that are
                            not easily determined by someone other than the
                            intended participant. This requirement protects
                            personal information and access to personal earnings
                            that are available for redemption.
                          </p>

                          <b>SSL Technology</b>
                          <p>
                            ER requires that a “secure session” is established,
                            using Secure Socket Layer (SSL) technology. This is
                            done anytime a participant supplies ER with personal
                            or confidential information in one of the secure
                            areas of a website.
                          </p>
                          <p>
                            SSL technology creates a private conversation that
                            only the participant’s computer and ER can
                            understand. The SSL technology encodes information
                            as it is being sent over the Internet between the
                            participant’s computer and ER’s system helping to
                            ensure that the transmitted information remains
                            confidential.
                          </p>

                          <p>
                            The use of SSL requires two components: and
                            SSL-compatible browser and a Web server to perform
                            “key-exchange” that establishes a secure connection
                            to ER web servers.
                          </p>
                          <b>Social Security Number (SSN)</b>
                          <p>
                            Social security number (SSN) is used for tax paying
                          </p>
                          <b>Browser Compliance</b>
                          <p>
                            Participants and client will need a browser with SSL
                            capabilities. Examples of SSL browsers include,
                            Microsoft’s Internet Explorer, Firefox, Chrome and
                            Safari. If a participant does not have a browser
                            with SSL capabilities, the participant can download
                            an SSL browser from the above mentioned SSL browser
                            list and will be required in order to securely
                            access personal or confidential information via the
                            Internet. ER codes sites to current browser version
                            minus 1. ER recommends the use of the latest browser
                            versions available. Accessing secure online areas
                            requires SSL capable browsers due to security
                            concerns.{" "}
                          </p>
                          <b>ISP Compliance</b>
                          <p>
                            Nearly all Internet Service Providers (ISPs)
                            automatically enable the SSL session described
                            above. If a participant or a client contact uses
                            their company's internal connection to access the
                            Internet they may find they cannot access the ER
                            secure pages with an SSL browser described above,
                            the company may be blocking access via a "firewall."
                            The company’s Internet access systems administrator
                            would have to be contacted for further details on
                            Internet access.{" "}
                          </p>
                          <b>Cookies</b>
                          <p>
                            A participant or client must have enabled cookies on
                            their browser in order to access confidential
                            information. If they have chosen to disable cookies
                            on their browser, they will not be able to access
                            confidential information.
                          </p>

                          <b>User ID and Password </b>
                          <p>
                            Access to all ER sites require the use of a Username
                            and Password as a security measure that helps
                            protect confidential information. This allows ER to
                            verify who is accessing the site, thereby allowing
                            access to account information, and preventing
                            unauthorized access.{" "}
                          </p>
                          <p>
                            In establishing unique Username and Password, the
                            following criteria should be adhered to:
                          </p>
                          <ul>
                            <li>
                              <span className="circle">❍ </span>
                              The Username and Password cannot be the same (e.g.
                              password / password)
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              The Username and Password must be at least six (6)
                              characters in length.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Commonly used configurations or easily determined
                              schema should not be used (e.g. 123456 / 654321)
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Usernames and Passwords should be personally
                              protected the same way debit or debit card PINs
                              are treated.
                            </li>
                          </ul>
                          <p>
                            Non-compliance with these criteria may permit others
                            to access the account and create mis-redemptions or
                            fraud. ER is not liable for accounts that do not
                            adhere to these criteria.
                          </p>
                          <p>
                            You should be aware that browser software often
                            "caches" a page as you look at it, meaning that some
                            pages are saved in your computer's temporary memory.
                            Therefore, you may find that clicking on your "Back"
                            button shows you a saved version of a previously
                            viewed page. Caching in no way affects the security
                            of your confidential Username or Password. If a
                            participant or client uses a computer in a public
                            place to access account information, they should
                            simply quit/exit the browser software before leaving
                            to minimize the possibility of anyone else viewing
                            their confidential information.{" "}
                          </p>
                          <b>Third Party Use</b>
                          <p>
                            ER does not sell, trade or rent personal information
                            to third parties. We do, however, share your
                            shipping information with our suppliers for the sole
                            purpose of delivering your redemption items.
                          </p>
                          <b>Secure Password Guidelines</b>
                          <p>
                            The combination of username and password define the
                            identity of users on a system. Adopting a{" "}
                            <b>
                              good personal password policy is the most
                              important barrier to unauthorized access
                            </b>{" "}
                            in current systems.{" "}
                          </p>
                          <p>Password Content</p>
                          <ul>
                            <li>
                              <span className="circle">❍ </span>
                              Mixture of numbers, capital letters, small
                              letters, punctuation.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Easy to remember (don't need to write it down).
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Easy to type quickly (difficult for an observer).{" "}
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Minimum acceptable format is six (6) characters in
                              length.
                            </li>
                          </ul>

                          <p>Examples</p>
                          <ul>
                            <li>
                              <span className="circle">❍ </span>
                              Choose a line or two of a poem, song etc. and use
                              just the first letters.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Join two small words with a strange character.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Invent an acronym.{" "}
                            </li>
                          </ul>

                          <p>
                            Password cracking software is fairly advanced and in
                            a lot of cases relies on a users habit of choosing
                            insecure passwords. Here are some common hSatteabits
                            that should be avoided. Bad examples
                          </p>
                          <ul>
                            <li>
                              <span className="circle">❍ </span>
                              Name of your spouse, parent, colleague, friend,
                              pet, towns, months, days.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Number of car/motorbike registration, telephone.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Common dictionary words (French, German, English,
                              Italian etc).
                            </li>
                            <li>
                              <span className="circle">❍ </span>A series of
                              identical numbers/letters.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Obvious keyboard sequences.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Any of the above in inverse or with a number
                              before or after.
                            </li>
                          </ul>
                          <b>Guidelines</b>
                          <ul>
                            <li>
                              <span className="circle">❍ </span>
                              Don't write it down, or disclose via email.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Default passwords should not be used.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Don't give your password to others.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              If passwords are disclosed on a system, change
                              them immediately.
                            </li>
                            <li>
                              <span className="circle">❍ </span>
                              Always change a default password.
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <h4 className="mb-0">Latest Order</h4> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}
