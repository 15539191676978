import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from "react-toastify";

export default function ViewRsa() {
  const [tableLoader, settableLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [resultData, setResultData] = useState([]);
  const [sortCol, setSortCol] = useState("true");
  const [sortDir, setSortDir] = useState("true");
  const [sortColErr, setSortColErr] = useState("");
  const [sortDirErr, setSortDirErr] = useState("");
  const [dirDisabled, setDirDisabled] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");
  const date = new Date();
  var time = date.getTime();

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();

  const rsaList = async (page, limit, values, sortCol, sortDir) => {
    if (sortCol && sortCol != "true") {
      setDirDisabled(false);
      setSortCol(sortCol);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }

    if (sortCol != "true" && sortDir == "true") {
      setSortDirErr("Please select this");
      return;
    } else if (sortCol == "true" && sortDir != "true") {
      setSortColErr("Please select this");
      return;
    } else if (sortCol != "true" && sortDir != "true") {
      setSortColErr("");
      setSortDirErr("");
    }

    settableLoader(true);
    try {
      const responce = await AdminListService.listRsa(
        page,
        limit,
        values,
        sortCol,
        sortDir
      );

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          id: value.id,
          sale_id: value.sale_id,
          location: value.location,
          store: value.store,
          first_name: value.first_name,
          last_name: value.last_name,
          password: value.password,
          email: value.email,
          address1: value.address1,
          state: value.state,
          city: value.city,
          zip: value.zip,
          spiff_amount: value.spiff_amount,
          created_at: value.created_at,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      // setResultData(res);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      rsaList("1", limit, []);
    } else {
      navigate("/admin/login", { replace: true });
    }
  }, []);

  const delRsa = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleRsa(
            delId,
            adminId
          );

          rsaList(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        rsaList("1", limit, values);
      },
    });

  const deleteSelected = async () => {
    var counter = singleSelections.length;
    if (counter > 0) {
      setLoading(true);
      try {
        await AdminListService.deleteSelectedRsa(singleSelections, adminId);
        setLoading(false);
        rsaList("1", limit, []);

        toast.success("Record(s) has been deleted!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (err) {
        setLoading(false);
      }
    } else {
      toast.error("Please select some record!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  var singleSelections = "";
  var allSelections = "";

  const changeSelection = () => {
    $(".slect_all").prop("checked", false);

    let selectedRecords = $(".table tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join("+");
    singleSelections = selectedRecords;
    $(".export-selection").attr(
      "href",
      `https://purple-api.elitestacks.com/v1/admin/export-selected-rsa/${selectedRecords}?time=${time}`
    );
  };

  const allSelect = () => {
    const selectAllCheckbox = $(".slect_all");
    const singleCheckboxes = $("input[name='single']:checkbox");

    selectAllCheckbox.prop("checked", !selectAllCheckbox.prop("checked"));
    singleCheckboxes.prop("checked", selectAllCheckbox.prop("checked"));

    const checkedVals = $(".select_one:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get();

    allSelections = checkedVals.join("+");

    $(".export-selection").attr(
      "href",
      `https://purple-api.elitestacks.com/v1/admin/export-selected-rsa/${allSelections}`
    );
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        {tableLoader ? (
          <div className="card-body pt-0">
            <Skeleton count={11} height="50px" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="text-nowrap table">
              <thead className="table-light">
                <tr>
                  <th
                    scope="col"
                    onClick={allSelect}
                    // className={getClassNamesFor("Select")}
                  >
                    <label htmlFor="Select">
                      <input
                        id="Select"
                        className="form-check-input slect_all"
                        type="checkbox"
                        onClick={allSelect}
                      />
                    </label>
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("id")}
                    className={getClassNamesFor("id")}
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("sale_id")}
                    className={getClassNamesFor("sale_id")}
                  >
                    Spiff Info
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("first_name")}
                    className={getClassNamesFor("first_name")}
                  >
                    User Info
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("spiff_amount")}
                    className={getClassNamesFor("spiff_amount")}
                  >
                    Total Rewards
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("created_at")}
                    className={getClassNamesFor("created_at")}
                  >
                    Date Registered
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("Action")}
                    className={getClassNamesFor("Action")}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((el, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <input
                          className="form-check-input select_one"
                          name="single"
                          id={el.id}
                          value={el.id}
                          type="checkbox"
                          onChange={changeSelection}
                        />
                      </td>
                      <td className="align-middle">
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td className="align-middle">
                        <table className="inner-table">
                          <tbody>
                            <tr>
                              <td>
                                <strong>RSA ID:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.sale_id ? el.sale_id : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Location:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.location ? el.location : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Store:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.store ? el.store : "N/A"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="align-middle">
                        <table className="inner-table">
                          <tbody>
                            <tr>
                              <td>
                                <strong>First Name:</strong>
                              </td>
                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.first_name ? el.first_name : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Last Name:</strong>
                              </td>

                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.last_name ? el.last_name : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Password:</strong>
                              </td>
                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.password ? el.password : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Email:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.email ? el.email : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Address:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.address1 ? el.address1 : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>State:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.state ? el.state : "N/A"}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>City:</strong>
                              </td>
                              <td>
                                <span className="text-control">
                                  {el.city ? el.city : "N/A"}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <strong>Zip:</strong>
                              </td>
                              <td>
                                {" "}
                                <span className="text-control">
                                  {el.zip ? el.zip : "N/A"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>

                      <td className="align-middle">
                        {el.spiff_amount ? el.spiff_amount : "N/A"}
                      </td>
                      <td className="align-middle">
                        {moment(el.created_at).format("MM-DD-YYYY")}
                      </td>

                      <td className="align-middle ">
                        <div className="d-flex align-items-center">
                          <Link
                            to={`/admin/edit-user/${el.id}`}
                            className="btn btn-success btn-sm btn-icon-text me-3"
                          >
                            Edit <i className="bi bi-pencil-square"></i>
                          </Link>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm btn-icon-text"
                            onClick={() => {
                              delRsa(el.id);
                            }}
                          >
                            Delete <i className="bi bi-x-circle"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  rsaList(e, limit, values, sortCol, sortDir);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">View RSA's</div>
          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">
                        Total RSA's:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-4 text-end">
                      <li className="dropdown">
                        <button
                          className="btn btn-primary back-blue dropdown-toggle viewRSA"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          id="RsaAction"
                        >
                          Action
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item delete-selection"
                              name="delete_selected"
                              href="javascript:void(0)"
                              onClick={(e) => {
                                deleteSelected(e);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete Selected
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item export-selection"
                              name="export_selected"
                              href="javascript:void(0)"
                              style={{ cursor: "pointer" }}
                            >
                              Export Selected
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${process.env.REACT_APP_API_Link}admin/export-all-rsa?time=${currentDateTime}`}
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                            >
                              Export All
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="sale_id">RSA ID</option>
                          <option value="location">Location</option>
                          <option value="store">Store</option>
                          <option value="first_name">First Name</option>
                          <option value="last_name">Last Name </option>
                          <option value="email">Email</option>
                          <option value="address">Address</option>
                          <option value="state">State</option>
                          <option value="city">City</option>
                          <option value="zip">Zip</option>
                          <option value="spiff_amount">Rewards Amount</option>
                          <option value="created_at">Register Date</option>
                        </select>
                        {errors.fieldtype && touched.fieldtype ? (
                          <span className="text-danger">
                            {errors.fieldtype}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="input-field-box mb-lg-0">
                        <label className="spiff-search">Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <span className="text-danger">
                            {errors.searchval}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => rsaList("1", e.target.value, values)}
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Column</label>
                        <select
                          className="form-select"
                          value={sortCol}
                          name="sort_column"
                          onChange={(e) =>
                            rsaList("1", limit, values, e.target.value, sortDir)
                          }
                        >
                          <option value>Select Column</option>
                          <option value="sale_id">RSA ID</option>
                          <option value="location">Location</option>
                          <option value="store">Store</option>
                          <option value="first_name">First Name</option>
                          <option value="last_name">Last Name </option>
                          <option value="email">Email</option>
                          <option value="address">Address</option>
                          <option value="state">State</option>
                          <option value="city">City</option>
                          <option value="zip">Zip</option>
                          <option value="created_at">Register Date</option>
                        </select>
                        {sortColErr && (
                          <span className="text-danger">{sortColErr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Direction</label>
                        <select
                          disabled={dirDisabled}
                          className="form-select"
                          value={sortDir}
                          onChange={(e) =>
                            rsaList("1", limit, values, sortCol, e.target.value)
                          }
                        >
                          <option value>Select Direction</option>
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        {sortDirErr && (
                          <span className="text-danger">{sortDirErr}</span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
