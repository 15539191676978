import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useFormik } from "formik";
import { useMask } from "@react-input/mask";
import Logo from "../../../Images/logo.webp";
import {
  contactUsValidate,
  signUpSchema,
  websiteChange,
} from "../../../schema";
import ReCAPTCHA from "react-google-recaptcha";
import UserService from "../../../services/user.service";
import Select from "react-select";
import ToTop from "../../admin/includes/ToTop";
import Swal from "sweetalert2";

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [errorsCapMsg, setErrorsCapMsg] = useState("");
  const [errorsCap, setErrorsCap] = useState(null);

  const [fieldError, setFieldError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    ssn: "",
    zip: "",
    state: "",
    city: "",
    password: "",
    signature: "",
  });
  const [emailError, setEmailError] = useState("");
  const [ssnerror, setSSNerror] = useState("");
  const [disable, setDisable] = useState(false);
  const [termsModel, setTermsModel] = useState(false);
  const state = useSelector((state) => state.stateVals);
  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [userCityLoader, setUserCityLoader] = useState(false);
  const [usererror, setUsererror] = useState("");

  const navigate = useNavigate();

  const inputRef = useMask({
    mask: "___-___-____",
    replacement: { _: /\d/ },
    showMask: true,
    separate: true,
  });

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await UserService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setUserState([...results]);
    };

    getUserState();
  }, []);

  const changeUserCity = (state) => {
    setUserCity([]);

    const getUserCity = async () => {
      setUserCityLoader(true);
      const { data } = await UserService.getCityByStateId(state);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.city,
          value: value.id,
        });
      });
      await setUserCity([...results]);
      setUserCityLoader(false);
    };

    if (state !== "") {
      getUserCity();
    }
  };

  const submission = async () => {
    setDisable(true);
    setLoading(true);
    try {
      const response = await UserService.validateRegister(values);

      if (response.status === 201) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.success("Registered, Login Now!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          navigate("/");
        }, 2000);
        // action.resetForm();
      }
    } catch (err) {
      if (err.response.status === 401) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.error("Error, Try Again Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 403) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);
        toast.error("Sorry, you are not allowed to register", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 422) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);
        setFieldError((prevErrors) => ({
          ...prevErrors,
          first_name: err?.response?.data?.first_name?.[0] || "",
          last_name: err?.response?.data?.last_name?.[0] || "",
          email: err?.response?.data?.email?.[0] || "",
          phone: err?.response?.data?.phone?.[0] || "",
          address1: err?.response?.data?.address1?.[0] || "",
          address2: err?.response?.data?.address2?.[0] || "",
          ssn: err?.response?.data?.ssn?.[0] || "",
          zip: err?.response?.data?.zip?.[0] || "",
          state: err?.response?.data?.state_id?.[0] || "",
          city: err?.response?.data?.city_id?.[0] || "",
          signature: err?.response?.data?.signature?.[0] || "",
          password: err?.response?.data?.password?.[0] || "",
        }));

        // setFieldError();

        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.error("Error, Some thing went wrong!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      fname: "",
      lname: "",
      ssn: "",
      address1: "",
      address2: "",
      state_id: "",
      city_id: "",
      password: "",
      confirm_password: "",
      zipcode: "",
      phone: "",
      signature: "",
      iAgree: false,
      termsAndConditions: false,
    },
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      if (usererror === "" && emailError === "" && ssnerror === "") {
        // LIVE captcha working
        if (errorsCap !== null) {
          submission();
          // setTermsModel(true);
          setErrorsCapMsg("");
        } else {
          setErrorsCapMsg("Captcha is not verified");
        }
      }
    },
  });

  const checkSSN = async (e) => {
    let ssn = e.currentTarget.value;
    if (ssn !== "" && ssn.length >= 11) {
      try {
        const response = await UserService.validateSSN(ssn);

        setFieldError((prevErrors) => ({
          ...prevErrors,
          ssn: "",
        }));
      } catch (err) {
        setFieldError((prevErrors) => ({
          ...prevErrors,
          ssn: err?.response?.data?.ssn?.[0] || "",
        }));
      }
    } else {
      setFieldError((prevErrors) => ({
        ...prevErrors,
        ssn: "",
      }));
    }
  };

  const checkuser = async () => {
    if (values.email !== "") {
      setDisable(true);
      try {
        const response = await UserService.validateUser(values);

        if (response.status === 200) {
          setDisable(false);

          setUsererror("");
        }
      } catch (err) {
        if (err.response.status === 422) {
          setDisable(false);
          setUsererror(err.response.data.response);
        } else {
          Swal.fire({
            title: "Error!",
            text: "Some thing went wrong!",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setUsererror("");
    }
  };
  // const checkemail = async (action) => {
  //   if (values.email !== "") {
  //     setDisable(true);
  //     try {
  //       const response = await UserService.validateEmail(values);

  //       if (response.status === 200) {
  //         setDisable(false);

  //         setEmailError("");
  //       }
  //     } catch (err) {
  //       if (err.response.status === 409) {
  //         setDisable(false);
  //         setEmailError(err.response.data.response);
  //       } else {
  //         setDisable(false);
  //         Swal.fire({
  //           title: "Error!",
  //           text: "Some thing went wrong!",
  //           icon: "error",
  //           confirmButtonText: "Try Again",
  //         });
  //       }
  //     }
  //   } else {
  //     setDisable(false);

  //     setEmailError("");
  //   }
  // };

  function onChange(value) {
    setErrorsCap(value);
    if (value === null) {
      setErrorsCapMsg("Captcha is not verified");
    } else {
      setErrorsCapMsg("");
    }
  }

  const ref = useMask({ mask: "___-__-____", replacement: { _: /\d/ } });
  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".errors-fields");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });

      }
    }, 500);
  };
  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design">
            <header className="main-header">
              <nav className="navbar navbar-expand-lg navbar-light flex-column">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} className="login-logo register" alt="" />
                </Link>
                <div className="alred-memb">
                  Already a member?
                  <Link className="round-red-btn" to="/">
                    LOGIN
                  </Link>
                </div>
              </nav>
            </header>
            <div className="user-heading-sectiom">
              REGISTRATION <span className="p-color">FORM</span>
              <div className="clearfix"></div>
            </div>

            <div className="row">
              <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
                <form className="row" onSubmit={handleSubmit} noValidate>
                  <ul className="timeline claim_detail">
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="alert alert-danger d-flex align-items-center font-17 "
                                role="alert"
                              >
                                <svg
                                  width="1em"
                                  height="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                  viewBox="0 0 16 16"
                                  role="img"
                                  fill="#58151c"
                                  aria-label="Warning:"
                                  style={{ marginTop: "-2px" }}
                                >
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div>
                                  {" "}
                                  Field(s) marked with Asterisk (*) are
                                  mandatory.
                                </div>
                              </div>
                              <h4 className="prf-hed">
                                SELECT EMAIL AND PASSWORD
                              </h4>{" "}
                              <ul className="circle-list">
                                <li>
                                  <span className="circle">❍ </span>
                                  Your password must be at least 6 characters
                                  long. For example a uppercase letter, a
                                  lowercase and digits.
                                </li>
                              </ul>
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    E-mail{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    checkUser={checkuser}
                                    className="form-control"
                                    placeholder="E-mail"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="email"
                                    value={values.email || ""}
                                  />
                                  {errors.email && touched.email ? (
                                    <span className="text-danger">
                                      {errors.email}
                                      {usererror ? (
                                        <p className="help text-danger errors-fields">
                                          {usererror}
                                        </p>
                                      ) : null}
                                    </span>
                                  ) : null}
                                  {fieldError.email && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.email}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="password"
                                    value={values.password || ""}
                                  />
                                  {errors.password && touched.password ? (
                                    <span className="text-danger errors-fields">
                                      {errors.password}
                                    </span>
                                  ) : null}
                                  {fieldError.password && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.password}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box mb-md-0">
                                  <label>
                                    Confirm Password
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm  Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="confirm_password"
                                    value={values.confirm_password || ""}
                                  />
                                  {errors.confirm_password &&
                                  touched.confirm_password ? (
                                    <span className="text-danger errors-fields">
                                      {errors.confirm_password}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                ENTER PERSONAL INFORMATION
                              </h4>{" "}
                              <ul className="circle-list">
                                <li>
                                  <span className="circle">❍ </span>
                                  Please note this information will be used for
                                  your 1099 form as well therefore make sure you
                                  enter the right information. You are not
                                  eligible for a card until you enter SSN.
                                </li>
                                <li>
                                  <span className="circle">❍ </span>
                                  We only accept residential address, please do
                                  not enter P.O Box Number or commercial
                                  addresses.
                                </li>
                              </ul>
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    First Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="fname"
                                    value={values.fname || ""}
                                  />
                                  {errors.fname && touched.fname ? (
                                    <span className="text-danger errors-fields">
                                      {errors.fname}
                                    </span>
                                  ) : null}
                                  {fieldError.first_name && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.first_name}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="lname"
                                    value={values.lname || ""}
                                  />
                                  {errors.lname && touched.lname ? (
                                    <span className="text-danger errors-fields">
                                      {errors.lname}
                                    </span>
                                  ) : null}
                                  {fieldError.last_name && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.last_name}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    SSN <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="XXX-XX-XXXX"
                                    onChange={handleChange}
                                    onKeyUp={checkSSN}
                                    onBlur={handleBlur}
                                    name="ssn"
                                    value={values.ssn || ""}
                                    ref={ref}
                                  />
                                  {!fieldError.ssn &&
                                  errors.ssn &&
                                  touched.ssn ? (
                                    <span className="text-danger errors-fields">
                                      {errors.ssn}
                                    </span>
                                  ) : null}
                                  {!errors.ssn && fieldError.ssn && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.ssn}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-field-box">
                                  <label>
                                    Address 1{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="address1"
                                    value={values.address1 || ""}
                                  />
                                  {errors.address1 && touched.address1 ? (
                                    <span className="text-danger errors-fields">
                                      {errors.address1}
                                    </span>
                                  ) : null}
                                  {fieldError.address1 && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.address1}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-field-box">
                                  <label>Address 2</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 2"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="address2"
                                    value={values.address2 || ""}
                                  />
                                  {errors.address2 && touched.address2 ? (
                                    <span className="text-danger errors-fields">
                                      {errors.address2}
                                    </span>
                                  ) : null}
                                  {fieldError.address2 && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.address2}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <div
                                    className={`form-floating ${
                                      errors.state_id && touched.state_id
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      classNamePrefix="react-select"
                                      className={`select_1 dropdown ${
                                        errors.state_id && touched.state_id
                                          ? "error-select-search"
                                          : ""
                                      }`}
                                      placeholder={"Select State"}
                                      options={userState}
                                      isSearchable={true}
                                      name="state_id"
                                      onChange={(selectedOption) => {
                                        setFieldValue("city_id", "");

                                        if (selectedOption) {
                                          setFieldValue(
                                            "state_id",
                                            selectedOption.value
                                          );
                                          changeUserCity(selectedOption.value);
                                        } else {
                                          setFieldValue("state_id", "");
                                        }
                                      }}
                                      onBlur={() =>
                                        setFieldTouched("state_id", true)
                                      }
                                    />
                                    <label className="select-label">
                                      State
                                      <span className="text-danger"> *</span>
                                    </label>
                                    {errors.state_id && touched.state_id ? (
                                      <p className="help text-danger errors-fields">
                                        {errors.state_id}
                                      </p>
                                    ) : null}
                                    {fieldError.state && (
                                      <p className="help text-danger errors-fields">
                                        {fieldError.state}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  {userCityLoader ? (
                                    <h5 className="card-title placeholder-glow">
                                      <span
                                        className="placeholder col-12"
                                        style={{
                                          height: "58px",
                                          marginTop: "4px",
                                        }}
                                      ></span>
                                    </h5>
                                  ) : (
                                    <div
                                      className={`form-floating ${
                                        errors.city_id && touched.city_id
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      <Select
                                        className={
                                          errors.city_id && touched.city_id
                                            ? "error-select-search"
                                            : ""
                                        }
                                        placeholder={"Select City"}
                                        options={userCity}
                                        isSearchable={true}
                                        name="city_id"
                                        onChange={(selectedOption) => {
                                          if (selectedOption) {
                                            setFieldValue(
                                              "city_id",
                                              selectedOption.value
                                            );
                                          } else {
                                            setFieldValue("city_id", "");
                                          }
                                        }}
                                        onBlur={() =>
                                          setFieldTouched("city_id", true)
                                        }
                                      />
                                      <label className="select-label">
                                        City{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      {errors.city_id && touched.city_id ? (
                                        <p className="help text-danger errors-fields">
                                          {errors.city_id}
                                        </p>
                                      ) : null}
                                      {fieldError.city && (
                                        <p className="help text-danger errors-fields">
                                          {fieldError.city}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Zip Code{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Zip Code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="zipcode"
                                    value={values.zipcode || ""}
                                  />
                                  {errors.zipcode && touched.zipcode ? (
                                    <span className="text-danger errors-fields">
                                      {errors.zipcode}
                                    </span>
                                  ) : null}
                                  {fieldError.zip && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.zip}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="input-field-box">
                                  <label>
                                    Phone Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="phone"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="phone"
                                    value={values.phone || ""}
                                    // ref={inputRef}
                                  />
                                  {errors.phone && touched.phone ? (
                                    <span className="text-danger errors-fields">
                                      {errors.phone}
                                    </span>
                                  ) : null}
                                  {fieldError.phone && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.phone}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                DIGITAL SIGNATURE AND AGREEMENT
                              </h4>{" "}
                              <ul className="circle-list"></ul>
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Electronic Signature{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Electronic Signature"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="signature"
                                    value={values.signature || ""}
                                  />
                                  {errors.signature && touched.signature ? (
                                    <span className="text-danger errors-fields">
                                      {errors.signature}
                                    </span>
                                  ) : null}
                                  {fieldError.signature && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.signature}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="chek-term">
                                  <input
                                    id="radio-1"
                                    className="radio-custom"
                                    name="iAgree"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="radio-1"
                                    className={`radio-custom-label terms-policy ${
                                      errors.iAgree && touched.iAgree
                                        ? "text-danger errors-fields"
                                        : ""
                                    }`}
                                  >
                                    &nbsp; I hereby confirm that the information
                                    provided above is correct and I authorize
                                    Purple Rewards to use my information.
                                  </label>

                                  {errors.iAgree && touched.iAgree ? (
                                    <p className="help text-danger errors-fields">
                                      {errors.iAgree}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="chek-term">
                                  <input
                                    id="radio-4"
                                    className="radio-custom"
                                    name="termsAndConditions"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="radio-4"
                                    className={`radio-custom-label terms-condition ${
                                      errors.termsAndConditions &&
                                      touched.termsAndConditions
                                        ? "text-danger errors-fields"
                                        : ""
                                    }`}
                                  >
                                    &nbsp;By registering for this promotion, I
                                    understand that promotion headquarters will
                                    send me periodic emails related to my
                                    status, participation, and general program
                                    announcements. Please see our{" "}
                                    <Link to="/term-conditions" target="_blank">
                                      terms and conditions
                                    </Link>{" "}
                                    and our
                                    <Link
                                      to="/privacy-statement"
                                      target="_blank"
                                    >
                                      {" "}
                                      privacy policy.
                                    </Link>
                                  </label>

                                  {errors.termsAndConditions &&
                                  touched.termsAndConditions ? (
                                    <p className="help text-danger errors-fields">
                                      {errors.termsAndConditions}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12">
                                <ReCAPTCHA
                                  sitekey={
                                    process.env.REACT_APP_API_GOOGLESITEKEY
                                  }
                                  name="captcha"
                                  onChange={onChange}
                                />
                                {errorsCapMsg !== "" ? (
                                  <p className="help text-danger errors-fields">
                                    {errorsCapMsg}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="reset"
                                  className="round-red-btn w-100 mt-3 reset-reg"
                                  value="RESET FORM"
                                  onClick={() => window.location.reload(false)}
                                />
                              </div>

                              <div className="col-lg-6">
                                <button
                                  type="submit"
                                  className="round-red-btn w-100 mt-3 "
                                  value="REGISTER"
                                  onClick={handleButtonClick}
                                  // disabled={disable}
                                >
                                  <span
                                    className="xspinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  REGISTER
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* <Footer /> */}
      <footer className="login-footer-main register">
        Copyright &copy; {new Date().getFullYear()}{" "}
        <span className="login-fotr-title">
          {process.env.REACT_APP_API_SITE_TITLE}
        </span>
        . All rights reserved.
      </footer>
      <ToTop />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}
