import React from "react";
import { Link, useLocation } from "react-router-dom";
import worldIcon from "../login/world.png";
import homeIcon from "../login/home.png";
import shopIcon from "../login/shop-bag.png";
import listIcon from "../login/list.png";
import colorIcon from "../login/colorIcon.png";
import { Helmet } from "react-helmet";

export default function Sidebar() {
  const locate = useLocation();

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_API_SITE_TITLE} Admin</title>
      </Helmet>

      <div id="sidebar" className="collapse collapse-horizontal show ">
        <div
          id="sidebar-nav"
          className="list-group border-0 rounded-0 text-sm-start min-vh-100"
        >
          <Link
            to="/admin/dashboard"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/dashboard" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            {/* <img src={homeIcon} alt="home-icon" style={{ width: "30px" }} /> */}
            <i className="bi bi-house-fill"></i>
            <span> Dashboard</span>{" "}
          </Link>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Website"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "true"
                : "false"
            }
            aria-controls="Website"
          >
            {/* <img src={worldIcon} alt="home-icon" style={{ width: "30px" }} /> */}
            <i className="bi bi-globe"></i>
            <span> Manage Website</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "show"
                : ""
            }`}
            id="Website"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-website"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-website" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Website</span>{" "}
            </Link>
            {/* <Link
              to="/admin/manage-newsletter"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-newsletter" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Newsletter</span>{" "}
            </Link> */}
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-reward" ||
              locate.pathname === "/admin/manage-reward"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Colors"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-reward" ||
              locate.pathname === "/admin/manage-reward"
                ? "true"
                : "false"
            }
            aria-controls="Colors"
          >
            <i className="bi bi-gift"></i>
            {/* <img src={colorIcon} alt="color-icon" style={{ width: "30px" }} /> */}
            <span> Manage Rewards</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-reward" ||
              locate.pathname === "/admin/manage-reward"
                ? "show"
                : ""
            }`}
            id="Colors"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-reward"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-reward" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Upload Rewards</span>{" "}
            </Link>
            <Link
              to="/admin/manage-reward"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-reward" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Rewards</span>{" "}
            </Link>
          </div>
          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-size" ||
              locate.pathname === "/admin/manage-size"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Size"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-size" ||
              locate.pathname === "/admin/manage-size"
                ? "true"
                : "false"
            }
            aria-controls="Size"
          >
            <img src={sizeIcon} alt="size-icon" style={{ width: "30px" }} />
            <span> Manage Sheet Size</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-size" ||
              locate.pathname === "/admin/manage-size"
                ? "show"
                : ""
            }`}
            id="Size"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-size"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-size" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Add Size</span>{" "}
            </Link>
            <Link
              to="/admin/manage-size"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-size" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Sizes</span>{" "}
            </Link>
          </div> */}

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/1099-report" ||
              locate.pathname === "/admin/archOrder"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#report"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/1099-report" ||
              locate.pathname === "/admin/archOrder"
                ? "true"
                : "false"
            }
            aria-controls="report"
          >
            <i className="bi bi-file-earmark-spreadsheet-fill"></i>
            {/* <img src={shopIcon} alt="shop-icon" style={{ width: "30px" }} /> */}
            <span> Reports</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/1099-report" ||
              locate.pathname === "/admin/archOrder"
                ? "show"
                : ""
            }`}
            id="report"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/1099-report"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/1099-report" ||
                locate.pathname === "/admin/archOrder"
                  ? "active"
                  : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View 1099 Reports</span>{" "}
            </Link>
          </div>
          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-job" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#job"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-job" ? "true" : "false"
            }
            aria-controls="job"
          >
            {/* <i className="bi bi-list-task"></i> */}
            <i className="bi bi-person-lines-fill"></i>
            {/* <img src={listIcon} alt="list-icon" style={{ width: "30px" }} /> */}
            <span> RSA'S</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/view-rsa" ? "show" : ""
            }`}
            id="job"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/view-rsa"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/view-rsa" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View RSA</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
